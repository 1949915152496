import { createReducer } from 'redux-act';
import { updateAppConfigAction, updateAppWindowWidthAction } from "../actions";

export default createReducer({
	[updateAppConfigAction]: (state, payload) => {
		return {...state, ...payload};
	},
	[updateAppWindowWidthAction]: (state) => {
		state.window_width = window.innerWidth;
		return {...state};
	}
}, {
	current_route: null,
	wrapper_class: 'Index',
	profit_percent: 0,
	minimum_deposit: 0,
	app_loading: true,
	minimum_withdrawal: 0,
	socket_link: window.location.hostname + ':5000',
	window_width: 1025,
	is_show_menu: false,
	win_value: 0,
	is_show_transaction: false
});
