const constants = {
  ROUTE: {
    REGISTER: "REGISTER",
    LOGIN: "LOGIN",
    FORGOT_PASSWORD: "FORGOT_PASSWORD",
    HOME: "HOME",
    TRADING: "TRADING",
    WALLET: "WALLET",
    PROFILE: "PROFILE",
    DASHBOARD: "DASHBOARD",
    AFFILIATE: "AFFILIATE",
    DEPOSIT: "DEPOSIT",
    TRANSFER: "TRANSFER",
    AGENCY: "AGENCY",
    CHALLENGE: "CHALLENGE",
    NOTIFICATION: "NOTIFICATION",
  },

  TRANSACTION_STATUS: {
    PENDING: "pending",
    FINISH: "finish",
  },

  TRANSACTION_TYPE: {
    BET: "bet",
    RECHARGE: "recharge",
    WITHDRAWAL: "withdrawal",
  },

  BASE_URL:
    process.env.NODE_ENV === "development"
      ? "http://localhost:8080"
      : window.location.protocol + "//" + window.location.hostname,
  BASE_API:
    process.env.NODE_ENV === "development"
      ? "http://localhost:8080/api"
      : window.location.protocol + "//" + window.location.hostname + "/api",
  SERVER_TIMEOUT: 30000,

  CHALLENGE_TYPE: {
    TRADING: "trading",
    AGENCY: "agency",
  },
  CHALLENGE_SCHEDULE: {
    MONTH: "month",
    WEEK: "week",
  },
};

export default constants;
